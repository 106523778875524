import { BarTooltipProps, ResponsiveBar } from '@nivo/bar';

import { ResponseTotalShareAccountExpenses } from '@/app/services/dashboardApi';
import { DATE_ISO_FORMAT, periodLabel } from '@/app/utils/dateUtils';
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const formatter = new Intl.NumberFormat('pt-BR');
const formatterMonetaryValue = (value: any) => `${formatter.format(value)}`;

function BarTooltip({ data, value, color, label }: BarTooltipProps<any>) {
  return (
    <Paper elevation={3} sx={{ p: 1 }}>
      <Box display="flex" flexDirection="row" gap={1}>
        Prédio:
        <Typography fontWeight={600}>{data.buildingAcronym}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap={1}>
        Período:
        <Typography fontWeight={600}>{periodLabel(data.period, 'pt-BR', DATE_ISO_FORMAT)}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap={1}>
        Centro de Resultado:
        <Typography fontWeight={600}>{data.costCenterCode}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap={1}>
        Valor total:
        <Typography fontWeight={600}>{`R$ ${formatterMonetaryValue(value)}`}</Typography>
      </Box>
    </Paper>
  );
}

interface TotalGoalsChartProps {
  data: Array<ResponseTotalShareAccountExpenses>;
}

function SharedExpenseChart({ data }: TotalGoalsChartProps) {
  const { i18n, t } = useTranslation('dashboards');

  const result = data
    ? data
      .filter((item) => item.accountingEntryType === 'DEBIT')
      .map((item: ResponseTotalShareAccountExpenses) => ({
        ...item,
      }))
    : [];

  return (
    <ResponsiveBar
      data={result}
      keys={['amount']}
      indexBy="costCenterCode"
      layout="horizontal"
      margin={{ top: 30, right: 130, bottom: 50, left: 100 }}
      valueFormat={formatterMonetaryValue}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      labelSkipWidth={80}
      labelSkipHeight={80}
      colors={{ scheme: 'nivo' }}
      colorBy="indexValue"
      tooltip={BarTooltip}
      animate
      enableLabel={false}
      enableGridX
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Centro de Resultado',
        legendPosition: 'middle',
        legendOffset: -80,
        truncateTickAt: 0,
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Total de debitos em R$',
        legendPosition: 'middle',
        legendOffset: 40,
        truncateTickAt: 0,
        format: formatterMonetaryValue,
      }}
      layers={['grid', 'axes', 'markers', 'bars', 'legends', 'annotations']}
    />
  );
}

export default SharedExpenseChart;
