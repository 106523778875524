import Box from '@mui/material/Box';

import { ResponseTotalShareAccountExpenses, useGetTotalShareAccountExpensesQuery } from '@/app/services/dashboardApi';
import {
  DATE_ISO_FORMAT,
  dateRange,
  getCurrentPeriod,
  monthFromPeriod,
  periodLabel,
  yearFromPeriod,
} from '@/app/utils/dateUtils';
import SharedExpenseChart from '@/components/dashboards/SharedExpensesChart';
import BuildingSelection from '@/components/projects/BuildingSelection';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function hasData(data: ResponseTotalShareAccountExpenses[] | undefined) {
  return data && data.length > 0;
}

function SharedExpensesDashboardPage() {
  const START_PERIOD = '2023-10';

  const periods = dateRange(getCurrentPeriod(), START_PERIOD).map((p) => `${p}-01`);
  const [buildingAcronym, setBuildingAcronym] = useState<string>('TIR');
  const [selectedPeriod, setSelectedPeriod] = useState<string>(periods[1]);

  const { data: dashboardDataExpense } = useGetTotalShareAccountExpensesQuery(
    {
      buildingAcronym,
      year: yearFromPeriod(selectedPeriod, DATE_ISO_FORMAT),
      month: monthFromPeriod(selectedPeriod, DATE_ISO_FORMAT),
    },
    { skip: !buildingAcronym },
  );

  const { i18n, t } = useTranslation('dashboards');

  return (
    <Box width="100%">
      <Typography variant="h1">{t('Total de Lançamentos de débito')}</Typography>
      <Typography variant="subtitle2" sx={{ marginTop: 1, paddingRight: 8 }}>
        O gráfico abaixo exibe o valor total dos lançamentos de débito por Centro de Resultado para o prédio e período
        selecionados.
      </Typography>
      <Box display="flex" flexDirection="row" gap={2}>
        <Box width="300px">
          <Typography variant="h3" sx={{ marginTop: 2 }}>
            {t('Prédio')}
          </Typography>
          <BuildingSelection buildingAcronym={buildingAcronym} setBuildingAcronym={setBuildingAcronym} />
        </Box>
        <Box width="300px">
          <Typography variant="h3" sx={{ marginTop: 2 }}>
            {t('Período')}
          </Typography>
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              size="small"
              sx={{ height: '32px', width: '100%', fontSize: '13px', padding: 0, margin: 0 }}
              value={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e.target.value)}
            >
              {periods.map((period) => (
                <MenuItem key={period} value={period}>
                  {periodLabel(period, i18n.language, DATE_ISO_FORMAT)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box height="60vh" width="80vw">
        {hasData(dashboardDataExpense) && (
          <SharedExpenseChart data={dashboardDataExpense || new Array<ResponseTotalShareAccountExpenses>()} />
        )}
        {!hasData(dashboardDataExpense) && (
          <Typography sx={{ marginTop: 2 }}>
            Não existem dados de lançamentos para o prédio e período selecionado.
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default SharedExpensesDashboardPage;
