import SidebarItem from '@components/sidebar/SidebarItem';
import MENU, { Menu, SubMenu } from '@constants/menu';
import { PAGE_PATHS } from '@constants/pages';
import Role from '@constants/roles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TreeView from '@mui/lab/TreeView';
import { Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Sidebar() {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('sidebar');

  const userRoles = (Object.values(Role) as Role[]).filter(
    (role) =>
      keycloak.hasResourceRole(role, process.env.REACT_APP_KEYCLOAK_CLIENT_ID) || process.env.REACT_APP_ENV === 'dev',
  );

  const showMenuTitle = (itemMenu: Menu): boolean => {
    const menuRoles = itemMenu.subMenus.map((subMenu) => subMenu.roles).flat();
    return userRoles.some((role) => menuRoles.includes(role));
  };

  const showSubMenu = (subMenu: SubMenu) => userRoles.some((role) => subMenu.roles.includes(role));

  const selectedTree = (endpoint: string) => {
    switch (endpoint) {
      case PAGE_PATHS.BUILDINGS:
        return '1';
      case PAGE_PATHS.EXPENSES:
        return '2';
      case PAGE_PATHS.PROJECTS:
        return '3';
      case PAGE_PATHS.PROCESS:
        return '4';
      case PAGE_PATHS.DASHBOARDS_GOALS:
        return '5';
      case PAGE_PATHS.DASHBOARDS_SHARED_EXPENSES:
        return '6';
      case PAGE_PATHS.PERMISSIONS:
        return '7';
      default:
        return '1';
    }
  };

  return (
    <TreeView
      selected={selectedTree(location.pathname)}
      aria-label="menu"
      defaultExpanded={[String(MENU[0].subMenus[0].id)]}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: 264, flexGrow: 1, minWidth: 180, maxWidth: 200, paddingLeft: '1.2rem', width: '100%' }}
    >
      {MENU.map((itemMenu: Menu) => (
        <div key={itemMenu.title}>
          {showMenuTitle(itemMenu) && (
            <Typography sx={{ mb: 1, fontWeight: 'bold', fontSize: 14 }}>{t(itemMenu.title)}</Typography>
          )}
          {itemMenu.subMenus.map((itemSubMenu: SubMenu) => (
            <div key={itemSubMenu.id}>
              {showSubMenu(itemSubMenu) && (
                <SidebarItem
                  active={selectedTree(location.pathname) === String(itemSubMenu.id)}
                  nodeId={String(itemSubMenu.id)}
                  labelText={t(itemSubMenu.label)}
                  color="#e3742f"
                  bgColor="#fcefe3"
                  onClick={() => {
                    navigate(itemSubMenu.navigate);
                  }}
                />
              )}
            </div>
          ))}
          <div style={{ margin: '10px' }} />
        </div>
      ))}
    </TreeView>
  );
}
