import MultSelecionItem from '@/components/common/MultSelectItem';
import GroupedOrStackedToggle, { GroupModeTypes } from '@/components/dashboards/GroupedOrStackedToggle';
import TotalGoalsChart from '@/components/dashboards/TotalGoalsChart';
import Box from '@mui/material/Box';

import { Building } from '@/app/models/buildings';
import { useGetBuildingsQuery } from '@/app/services/buildingsApi';
import { ResponseTotalGoals, useTotalGoalsQuery } from '@/app/services/dashboardApi';
import { DATE_ISO_FORMAT, dateRange, getCurrentPeriod, periodLabel } from '@/app/utils/dateUtils';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function getBuildingacronyms(buildingList: Array<Building> | undefined): Array<string> {
  return buildingList ? buildingList.map((b: Building) => b.acronym) : [];
}
function TotalGoalsPage() {
  const { data: buildings } = useGetBuildingsQuery({ searchTerm: '', active: true });

  const START_PERIOD = '2023-10';

  const periods = dateRange(getCurrentPeriod(), START_PERIOD).map((p) => `${p}-01`);
  const [buildingAcronymList, setBuildingAcronymList] = useState<Array<string>>([]);
  const [groupMode, setGroupMode] = useState<GroupModeTypes>('stacked');
  const [selectedBuildings, setSelectedBuildings] = useState<string[]>([]);
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>(periods);

  const { data: dashboardData } = useTotalGoalsQuery(
    {
      buildingAcronym: buildingAcronymList,
      period: periods,
    },
    { skip: !Array.isArray(buildingAcronymList) || buildingAcronymList.length === 0 },
  );

  useEffect(() => {
    setBuildingAcronymList(getBuildingacronyms(buildings));
  }, [buildings]);

  useEffect(() => {
    setSelectedBuildings(buildingAcronymList);
  }, [buildingAcronymList]);

  const { t } = useTranslation('dashboards');

  return (
    <Box width="100%">
      <Typography variant="h1">{t('Total de Metas por Prédio e Período')}</Typography>
      <Typography variant="subtitle2" sx={{ marginTop: 1, paddingRight: 8 }}>
        O gráfico abaixo exibe o valor total das metas de todos os centros de Resultado por prédio e período
        selecionado. Ao selecionar o tipo de gráfico Empilhado, é exibido no topo de cada barra o somatório das metas
        dos prédios no período.
      </Typography>
      <MultSelecionItem
        fieldLabel={t('Prédios')}
        allItems={buildingAcronymList}
        selectedItems={selectedBuildings}
        setSelectedItems={setSelectedBuildings}
      />
      <MultSelecionItem
        fieldLabel={t('Períodos')}
        allItems={periods}
        selectedItems={selectedPeriods}
        setSelectedItems={setSelectedPeriods}
        labelFnc={(value) => periodLabel(value, 'pt-BR', DATE_ISO_FORMAT)}
      />
      <GroupedOrStackedToggle fieldLabel={t('Tipo de Gráfico')} groupMode={groupMode} setGroupMode={setGroupMode} />
      <Box height="60vh" width="80vw">
        <TotalGoalsChart
          data={dashboardData || new Array<ResponseTotalGoals>()}
          selectedBuildings={[...selectedBuildings].sort().reverse()}
          selectedPeriods={selectedPeriods}
          groupMode={groupMode}
        />
      </Box>
    </Box>
  );
}

export default TotalGoalsPage;
